import React from 'react'
import TimelinePreview from './timeline-preview'

import styles from './timeline-preview-list.module.css'

function TimelinePreviewGrid (props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <TimelinePreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

TimelinePreviewGrid.defaultProps = {
  title: '',
  nodes: []
}

export default TimelinePreviewGrid
