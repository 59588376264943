import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getTimelineUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './timeline-preview.module.css'

function TimelinePreview (props) {
  return (
    <div
      className={props.isInList ? styles.inList : styles.inGrid}
    >
      <div className={styles.text}>
        <h1 className={styles.title}><Link to={getTimelineUrl(props.slug.current)}>{props.title}</Link></h1>
        
        {props._rawExcerpt && (
          <div className={styles.excerpt}>
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}

        <p className={styles.leiamais}><Link to={getTimelineUrl(props.slug.current)}>Leia mais</Link></p>

      </div>
      
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <Link to={getTimelineUrl(props.slug.current)}><img
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto('format')
              .url()}
            alt={props.mainImage.alt}
          /></Link>
        )}
      </div>
    </div>
  )
}

export default TimelinePreview
