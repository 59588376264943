import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import TimelinePreviewGrid from '../components/timeline-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import Hero from '../components/hero'
import Fio from '../components/fiodecontas'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query TimelinePageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    timelines: allSanityTimeline(
      sort: { fields: [startDate], order: ASC }
      filter: { slug: { current: { ne: null } }, startDate: { ne: null } }
    ) {
      edges {
        node {
          id
          startDate
          endDate
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const TimelinePage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const timelineNodes = (data || {}).timelines
    ? mapEdgesToNodes(data.timelines)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={"Linha do Tempo"}
        description={site.description}
        keywords={site.keywords}
      />
      <Fio>
        <Hero />
          <Container>
            {timelineNodes && (
              <TimelinePreviewGrid
                nodes={timelineNodes}
              />
            )}
          </Container>
      </Fio>
    </Layout>
  )
}

export default TimelinePage
